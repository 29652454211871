import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/camera',
    name: 'camera',
    component: () => import('../views/CameraView.vue')
  }, 
  {
    path: '/invest',
    name: 'invest',
    component: () => import('../views/investView.vue')
  }, 
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/Notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    
    redirect: "/404",
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/mainView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
